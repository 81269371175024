import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MainService } from '../services/main.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationResolverService implements Resolve<any> {

  constructor(
    private mainService: MainService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const data = {
      operation_type: 'select',
      service_type: 'edu_application',
      applicationId: route.paramMap.get('application_id')
    }
    console.log(route.paramMap.get('application_id'))

    return this.mainService.getApplication(data);
  };
}
