import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateCountdownService {

  public dateSubject = new BehaviorSubject({});

  public interval: any;

  constructor() {
  }

  countdown(deadline) {
    let nowMiliseconds = new Date().getTime();
    let deadlineMiliseconds = new Date(deadline).getTime();
    let t = deadlineMiliseconds - nowMiliseconds;

    this.interval = setInterval(() => {
      nowMiliseconds = new Date().getTime();
      t = deadlineMiliseconds - nowMiliseconds;
      let days = Math.floor(t / 86400000); // 1000 * 60 * 60 * 24
      let hours = Math.floor((t % 86400000) / 3600000); // 1000 * 60 * 60
      let minutes = Math.floor((t % 3600000) / 60000); // 1000 * 60
      let seconds = Math.floor((t % 60000) / 1000);

      this.dateSubject.next(
        {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        }
      );
      if (t < 0) {
        this.clearIntervalFunction();
        
        // TODO: burayi kontrol et !
        this.dateSubject.next(
          {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
          }
        );
      }
    }, 1000);

  }
  clearIntervalFunction() {
    clearInterval(this.interval);
  }
}
